import React, { Component } from 'react';
import {Map, GoogleApiWrapper} from 'google-maps-react';


class SearchAutoComplete extends Component {

  constructor(props){

      super(props);

      this.state = {
          isValid: null,
          position: null,
          isLoading: false
      };
  
      this.onSubmit = this.onSubmit.bind(this);
      this.renderAutoComplete = this.renderAutoComplete.bind(this);
      this.onUpdateSearch = this.onUpdateSearch.bind(this);
      this.onResetSearch = this.onResetSearch.bind(this);
    
  }
  

  componentDidMount() {
    //this.renderAutoComplete();

    if(this.props.isAuto){
      //alert('isAuto');
      this.autocomplete.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps.map) this.renderAutoComplete();
  }

  onSubmit(e) {
    e.preventDefault();
  }

  renderAutoComplete() {
    const { google, map, restrict, country, theme } = this.props;

    if (!google || !map) return;

    var componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_2: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
    
    var options = {};

    if(restrict === 'country'){
      options = {
        componentRestrictions: {
          country: [country]
        }
      };
    }
    else{
      options = {}
    }

    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete, options);
    autocomplete.bindTo('bounds', map);

    autocomplete.addListener('place_changed', () => {

      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
      else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      let Dictionary = {};

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];
          Dictionary[addressType] = val;
        }
      }

      var FOUND_CITY = place.address_components[0].long_name;
      
      var CURRENT_LOCATION = place.geometry.location;
      var CURRENT_TYPE = place.types;
      
      var FOUND_LATITUDE = CURRENT_LOCATION.lat();
      var FOUND_LONGITUDE = CURRENT_LOCATION.lng();

      var FOUND_STREET = null;
      var FOUND_COMPONENTS = place.address_components;
      var FOUND_ADDRESS =  place.formatted_address;

      var FOUND_COUNTRY = null;
      var FOUND_NUMBER = null;
      var FOUND_STATE = null;
      var FOUND_PROVINCE = null;
      var FOUND_POSTAL = null;
        

      for(var i=0; i<FOUND_COMPONENTS.length; i++){

          var COMPONENT = FOUND_COMPONENTS[i];
          var TYPE = COMPONENT.types[0] ;

          if(TYPE === 'route'){
              FOUND_STREET = COMPONENT['long_name'];
          }

          if(TYPE === 'street_number'){
              FOUND_NUMBER = COMPONENT['short_name'];
          }

          if(TYPE === 'country'){
              FOUND_COUNTRY = COMPONENT['short_name'];
          }

          if(TYPE === 'administrative_area_level_1'){
              FOUND_STATE = COMPONENT['long_name'];
          }

          if(TYPE === 'administrative_area_level_2'){
              FOUND_PROVINCE = COMPONENT['short_name'];
          }

          if(TYPE === 'locality'){
              FOUND_CITY = COMPONENT['short_name'];
          }

          if(TYPE === 'postal_code'){
              FOUND_POSTAL = COMPONENT['short_name'];
          }


      }

      let FOUND_LOCATION = {};

      if(theme === 'provider'){
        FOUND_LOCATION = {
            latitude: FOUND_LATITUDE, 
            longitude: FOUND_LONGITUDE,
            city: FOUND_CITY,
            address: FOUND_ADDRESS,
            country: FOUND_COUNTRY,
            state: FOUND_STATE,
            province: FOUND_PROVINCE,
            postal : FOUND_POSTAL,
            number: FOUND_NUMBER,
            types: CURRENT_TYPE,
        }
      }
      else{

        FOUND_LOCATION = {
            city: FOUND_CITY,
            input: this.autocomplete.value,
        }

        const FOUND_LOCATION_ENCODED = new URLSearchParams(FOUND_LOCATION).toString();
        const FOUND_LOCATION_ENCRYPTED = window.btoa(FOUND_LOCATION_ENCODED);
        //const FOUND_LOCATION_DECRIPTED = window.atob(FOUND_LOCATION_ENCRYPTED);

        FOUND_LOCATION = {
            latitude: FOUND_LATITUDE, 
            longitude: FOUND_LONGITUDE,
            address: FOUND_LOCATION_ENCRYPTED
        }
      }
      

      this.setState({isValid: 'true'});
      this.props.onSelectLocation(FOUND_LOCATION);

      this.setState({ position: place.geometry.location });

    
    });

  }


  async onUpdateSearch(city){

        // const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        // if(!JWT){
        //   this.props.onFetchUser(null, {
        //     city: city.name,
        //     geocode: {
        //       latitude: city.latitude, 
        //       longitude: city.longitude
        //     }
        //   });
        //   return
        // }

        // const FORM = {
        //     city: city,
        //     type: 'update_location'
        // }

        // console.log("FORM TO UPLOAD ===> ", FORM);

        // this.setState({isLoading: true});

        // const DATA_RESPONSE = await postApiPrivate('/users/search', FORM , JWT);
         
        // if(DATA_RESPONSE.error){
        //     console.log("ERROR FROM API SAVING USER", DATA_RESPONSE.error);
        //     return 
        // }
        
        // this.props.onFetchUser(JWT);

        // this.setState({isLoading: false});
  }

  onResetSearch(){

    this.setState({isValid: null});
    this.autocomplete.value = '';
    
  }


  render() {

    const {TRANSLATE, placeholder, onConfirmAddress, currentAddress, isLoading, className} = this.props;
    const {isValid} = this.state;

    return (
      <>        
          <input placeholder={placeholder} 
                  ref={ref => (this.autocomplete = ref)} 
                  type='text'
                  className={className}
          />
      </>
    );
  }
}
 
const MapWrapper = props => (
  <Map className="w-full" google={props.google} visible={false} containerStyle={{position: 'inherit', width: '100%'}} style={{width: '100%'}}>
    <SearchAutoComplete {...props} />
  </Map>
);



export default GoogleApiWrapper(
  (props) => ({
    apiKey: 'AIzaSyAjmzH1E9YNg4HdX3b3eufVPSHhE2T6nNI',
    language: props.LANGUAGE
  }
))(MapWrapper)
import React, { useEffect, useState, useRef } from 'react';

import LoaderMaster from '../Loaders/Loaders';

import {postApiPrivate} from '../../api';

import moment from 'moment';

import { Avatar, Chip } from '@mui/material';

import { PaperPlane } from 'react-ionicons';

import socketIOClient from "socket.io-client";

//import { API_SIMPLE } from '../../api';

import '../Inbox/z_inbox.css';


const API_SIMPLE = 'https://babonbo.herokuapp.com'; //LIVE

const AdminInboxDetails = (props) => {

    const {USER, TRANSLATE, match} = props;
  
    const [isLoading, setLoading] = useState(true);
  
    
    const [CURRENT_ROOM, setCurrentRoom] = useState(null);
    const [CURRENT_USER, setCurrentUser] = useState(null);
    const [OTHER_USER, setOtherUser] = useState(null);
    const [BOT_USER, setBotUser] = useState({
      _id: 'BOT_USER',
      avatar: 'https://previews.123rf.com/images/vectorwin/vectorwin2002/vectorwin200206983/140008184-chat-bot-icon-vector-thin-line-sign-isolated-contour-symbol-illustration.jpg',
      name: 'bot'
    })
   
    useEffect(()=> {
      if(USER.name){

        const conversation_id = match.params.conversation;

        onFetchConversation({
            conversation_id
        });

      }

    }, [USER]);
  
    const onFetchConversation = async({conversation_id}) => {
  
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  
        const POST_FORM = {
          id: conversation_id
        }
  
        setLoading(true);
  
        try{
  
            const DATA_RESPONSE = await postApiPrivate('/admin/messages/chat',  POST_FORM , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING INBOX DETAILS", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }
  
  
            const DATA_FETCHED = DATA_RESPONSE || {};
            
            const conversation_id = DATA_FETCHED.i || '';
            const customer_obx = DATA_FETCHED.c || {};
            const store_obx = DATA_FETCHED.s || {};
  
            const MODEL_CUSTOMER = {
              _id: customer_obx.id,
              name: customer_obx.name,
              avatar: customer_obx.img,
            }
  
            const MODEL_STORE = {
              _id: store_obx.id,
              name: store_obx.name,
              avatar: store_obx.img,
            }
  
            setCurrentRoom(conversation_id);
            setCurrentUser(MODEL_STORE);
            setOtherUser(MODEL_CUSTOMER);         

            setLoading(false);
  
            return {
              i: conversation_id,
              s: MODEL_STORE,
              c: MODEL_CUSTOMER
            }
  
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
  
            return null
        }
  
    }

  
    if(isLoading){
          return (
              <LoaderMaster title={TRANSLATE.loader_loading} type={"secondary"}/>
          )
    }
  
    if(CURRENT_ROOM){
          return (
            <InboxProviderChat  CURRENT_ROOM={CURRENT_ROOM} 
                        CURRENT_USER={CURRENT_USER}
                        OTHER_USER={OTHER_USER}
                        BOT_USER={BOT_USER}
                        TRANSLATE={TRANSLATE}
                        {...props}

            />
          )
    }
  
    return null
    
}
  
const InboxProviderChat = (props) => {
  
    const {CURRENT_ROOM, CURRENT_USER, BOT_USER, OTHER_USER, TRANSLATE, history, match} = props;
  
    const [isLoading, setLoading] = useState(false);
    const [inputText, setInputText] = useState('');
    const [MESSAGES, setMessages] = useState([]);
  
    const socket = socketIOClient(API_SIMPLE, {
      transports: ['websocket'],
    });
  
    useEffect(() => {
  
        onFetchMessages();
      
    }, []);

    useEffect(() => {
  
        // Message from server
        socket.on('message', ({senderId, recipientId, roomId, message}) => {
                
            console.log('DATA MESSAGE ===> FROM SERVER', JSON.stringify({senderId, recipientId, roomId, message}));
            var senderUser = {}
  
            if(recipientId === 'BOT'){
              console.log("BBBB");
              senderUser = BOT_USER;

            }
            else{
  
              if(senderId === CURRENT_USER._id){
                console.log("AAAA");
                senderUser = CURRENT_USER;
              }
              else if(senderId === OTHER_USER._id){
                console.log("CCCC");
                senderUser = OTHER_USER
  
              }
  
            }
          
            var mapped_messages = [{
                "_id": guidGenerator(),
                createdAt: new Date(),
                text:  message,
                user: senderUser
              }
            ]
  
            setMessages(
              previousMessages => 
              [...mapped_messages, ...previousMessages]
            )
        });
  
        
        return () => {
          socket.disconnect();
        };
  
  
    }, []);
  
  
    const onJoinRoom = () => {
      
      console.log("PPPPPPP ===> ", {
        roomId: CURRENT_ROOM,
        senderId: CURRENT_USER._id
      });
  
      // Join chatroom
      socket.emit('joinRoom', {
        roomId: CURRENT_ROOM,
        senderId: CURRENT_USER._id
      });
  
    }
  


    const onFetchMessages = async() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  
        setLoading(true);
  
        try{
  
            const DATA_RESPONSE = await postApiPrivate('/admin/messages/messages', {id: CURRENT_ROOM } , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING INBOX SINGLE", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }
  
           // console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
            var messages_array = DATA_RESPONSE.data || [];
            var messages_formatted = getFormattedMessages(messages_array, CURRENT_USER, OTHER_USER);
  
           // console.log("DATA FORMATTED = ", messages_formatted);
  
            setMessages(messages_formatted);
            setLoading(false);

            setTimeout(()=> {
              onJoinRoom(CURRENT_ROOM);
            }, 2000);

  
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
        }
  
    }
   
    const onSendText = (text) => {
     
       var INPUT_TEXT = text;
 
       if(INPUT_TEXT.length >0){
 
         onSend(INPUT_TEXT);
 
         setInputText('');
 
       }
     
    }

    const onPressEnter = (event) => {
      
      if(event.key === 'Enter'){
  
          var INPUT_TEXT = event.target.value;
  
          if(INPUT_TEXT.length >0){
    
            onSend(INPUT_TEXT);
  
            setInputText('');
  
          }
  
      }
    
    }

    const onSendMessage = async(text) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  
        try{
  
            const FORM = {
              id: CURRENT_ROOM,
              text: text,
              sid: CURRENT_USER._id
            }
  
            console.log("FORM TO SEND ===> ", FORM);
  
            const DATA_RESPONSE = await postApiPrivate('/admin/messages/send', FORM , JWT);
            const DATA_ERROR = DATA_RESPONSE.error;
            const DATA_STATUS = DATA_RESPONSE.status;
            
            if(DATA_ERROR || DATA_STATUS === 'error'){
              return {status: 'error'} 
            }
  
            if(DATA_STATUS === 'success'){
              return {status: 'success'}
            }
            
            return {status: 'error'} 
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            
            return {status: 'error'}
        }
  
  
    }
  
    const onSend = async(message) => {
  
        console.log("NEW MESSAGE ===> ", message)
  
        if(!message){
          return
        }

        var message_text = message;
  
        console.log("SEND MESSAGE PAYLOAD ID ===> ", {
          roomId: CURRENT_ROOM,
          senderId: CURRENT_USER._id,
          recipientId: OTHER_USER._id,
          message: message_text
        });
  
        socket.emit('chatMessage', {
          roomId: CURRENT_ROOM,
          senderId: CURRENT_USER._id,
          recipientId: OTHER_USER._id,
          message: message_text
        });
      
        onSendMessage(message_text).then((result)=> {
            var result_status = result.status;
            console.log("ONSEND ===> ", result_status);
        })
  
        return
  
    };
  
    const guidGenerator = () => {
        var S4 = function(){
            return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }


    
  
  
    if(isLoading){
        return (
            <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
        )
    }
  
    return (

      <div className='container-fluid' style={{background: '#f0f5f9'}}>

          <div className='row'>

            <div className='col-xl-9 col-md-6 col-12 order-lg-1 order-2'>
                <GiftedChat
                  messages={MESSAGES}
                  onSend={(message) => console.log("MESSAGE SENT")}
                  placeholder={TRANSLATE.inbox_chat_placeholder}
                  user={CURRENT_USER}
                  locale={TRANSLATE.lang}
                  dateFormat={'dddd LL'}
                  style={{border: 'solid 1px #00000019', padding: 20, background: '#fff', borderRadius: 20, height: 'calc(100vh - 200px)'}}
          
                />
            </div>

            <div className='col-xl-3 col-md-6 col-12 order-lg-2 order-1'>

              <div style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20, border: 'solid 1px #00000019'}}>

                <div className="top_box_magic">
                    <h1>
                      Customer
                    </h1>

                    <Chip
                        avatar={<Avatar alt={OTHER_USER.name} src={OTHER_USER.avatar} />}
                        label={OTHER_USER.name.toLowerCase()}
                        variant="outlined"
                        size='medium'
                        style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                        onClick={()=> history.push('/admin/users/profile/'+OTHER_USER._id)}
                    />
                    
                </div>

                <div className="top_box_magic">
                    <h1>
                      Store
                    </h1>

                    <Chip
                        avatar={<Avatar alt={CURRENT_USER.name} src={CURRENT_USER.avatar} />}
                        label={CURRENT_USER.name.toLowerCase()}
                        variant="outlined"
                        size='medium'
                        style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                        onClick={()=> history.push('/admin/stores/profile/'+CURRENT_USER._id)}
                    />
                    
                </div>

                <div className="top_box_magic">

                      <h1>
                        Chat with Customer
                      </h1>
                      <textarea placeholder={TRANSLATE.inbox_chat_placeholder} onKeyPress={(e)=>onPressEnter(e)} 
                                onChange={(e)=> setInputText(e.target.value)}
                                value={inputText}  
                                style={{border: 'solid 1px #00000050', padding: 20, background: '#fff', borderRadius: 10, marginTop: 10, minHeight: 300}}
                      />

                      <div className='d-flex align-items-center justify-content-center' style={{padding: '0px 10px', borderRadius: 20, height: 40, width: 'fit-content', background: (inputText.trim().length>0 ? '#26BFBF' : '#ccc'), cursor: (inputText.length>0 ? 'pointer' : 'not-allowed'), color: '#fff', marginTop: 20}} onClick={()=> onSendText(inputText)}>
                            Send Message<PaperPlane color={'#ffffff'} style={{marginLeft: 10, marginTop: 5}}/>
                      </div>
                  
                </div>

              </div>
                


            </div>

          </div>

         
      
      </div>
  
    )
}
  
const getFormattedMessages = (messages_array, CURRENT_USER, OTHER_USER) => {

    if(!messages_array || messages_array.length == 0){
    return []
    }
    
    var formatted_messages = messages_array.map((item)=> {

        var id = item.id;
        var text = item.text;
        var createdAt = item.date;
        var from = item.from;

        var user = (from === CURRENT_USER._id) ? CURRENT_USER : OTHER_USER;

        var final_result = text.replace(/\d(?!\d{0,3}$)/g, '#');

        console.log("FINAL RESULTS ===> ", final_result);

        return Message_Item({id, text: text, createdAt, user});
    })

    return formatted_messages;
    
}
  
const Message_Item = ({id, text, createdAt, user}) => {

    return {
        _id: id,
        text: text,
        createdAt: new Date(createdAt),
        user: user
    }

}

//Chat Container
const GiftedChat = ({messages, onSend, placeholder, user, locale, dateFormat, style}) => {

  console.log("MESSAGES ===> ", messages);

 
  // this gives an object with dates as keys
  const groups = messages.reduce((groups, message) => {
    
    const {createdAt} = message

    const message_date = moment(createdAt).format('dddd LL');
    const date = message_date;

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);

    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      messages: groups[date]
    };
  });

  console.log("GROUP ARRAY ===> ", groupArrays);


  const currentChat = groupArrays.map((item, index) => {

      const group_date = item.date;
      const group_messages = item.messages || [];

      const currentMessages = group_messages.map((item, index) => {

            const message_id = item._id;
            const message_text = item.text;
            const message_createdAt = moment(item.createdAt).format('HH:mm');
            const message_user = item.user;
      
            if(message_user._id === user._id){
                return (
                  <div key={index} className="bubble_master">
                    <div className="bubble_user" style={{background: '#26BFBF'}}>
                        {message_text}
                    </div>
                    <div className="chat_date_message">
                      {message_createdAt}
                    </div>
                  </div>
                )
            }
            else{
      
                return (
                  <div key={index} className="bubble_master_other">
                    <div className="bubble_other" style={{background: '#fd6270', color: '#fff'}}>
                        {message_text}
                    </div>
                    <div className="chat_date_message">
                      {message_createdAt}
                    </div>
                  </div>
                  
                )
            }

      }) 


      return (
        <React.Fragment key={index}>  
            {currentMessages}
            <div className='chat_date'>{group_date}</div>
        </React.Fragment>
      )

  });

  




  return (
    <>
        <div className="inbox_chat_container" style={style}>
            {currentChat}
        </div>
    </>
  )

}
  


export default AdminInboxDetails

